<template>
    <div>
        <v-icon color="primary" @click="setFilterShow">mdi-filter-plus</v-icon>
        <v-list class="filter_left mt-5 background_color_transparent">
            <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                <template v-slot:activator class="px-0">
                    <v-list-item-title :ripple="false" class="main_filter">{{$t('reports')}}</v-list-item-title>
                </template>
                <v-list-item :ripple="false" v-for="(item, i) in items" :key="i" link :class="item.route.name === $route.name ? 'active' : ''">
                    <v-list-item-title v-text="$t(item.title)" class="cursor-pointer" @click="goToMenu(item.route)"></v-list-item-title>
                    <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'ReportMenu',
    data() {
        return {
            items: [
                {
                    title: this.$t('menu_calls'),
                    route: {name: "report_call"},
                    visible: this.can(['administrator','director']),
                },
            ],
            group1: true,
            filter_show: false,
        }
    },
    methods: {
        setFilterShow() {
            this.filter_show===true ? this.filter_show=false : this.filter_show=true
            this.$emit("filter_show", this.filter_show);
        },
        goToMenu(route) {
            this.$router.push(route)
        },
    }
};
</script>
